let wasm;
export function __wbg_set_wasm(val) {
    wasm = val;
}


const heap = new Array(128).fill(undefined);

heap.push(undefined, null, true, false);

function getObject(idx) { return heap[idx]; }

let heap_next = heap.length;

function dropObject(idx) {
    if (idx < 132) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

const lTextDecoder = typeof TextDecoder === 'undefined' ? (0, module.require)('util').TextDecoder : TextDecoder;

let cachedTextDecoder = new lTextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachedUint8Memory0 = null;

function getUint8Memory0() {
    if (cachedUint8Memory0 === null || cachedUint8Memory0.byteLength === 0) {
        cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

let WASM_VECTOR_LEN = 0;

const lTextEncoder = typeof TextEncoder === 'undefined' ? (0, module.require)('util').TextEncoder : TextEncoder;

let cachedTextEncoder = new lTextEncoder('utf-8');

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length, 1) >>> 0;
        getUint8Memory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len, 1) >>> 0;

    const mem = getUint8Memory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3, 1) >>> 0;
        const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
        ptr = realloc(ptr, len, offset, 1) >>> 0;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

let cachedUint32Memory0 = null;

function getUint32Memory0() {
    if (cachedUint32Memory0 === null || cachedUint32Memory0.byteLength === 0) {
        cachedUint32Memory0 = new Uint32Array(wasm.memory.buffer);
    }
    return cachedUint32Memory0;
}

function passArrayJsValueToWasm0(array, malloc) {
    const ptr = malloc(array.length * 4, 4) >>> 0;
    const mem = getUint32Memory0();
    for (let i = 0; i < array.length; i++) {
        mem[ptr / 4 + i] = addHeapObject(array[i]);
    }
    WASM_VECTOR_LEN = array.length;
    return ptr;
}

function _assertClass(instance, klass) {
    if (!(instance instanceof klass)) {
        throw new Error(`expected instance of ${klass.name}`);
    }
    return instance.ptr;
}

let cachedInt32Memory0 = null;

function getInt32Memory0() {
    if (cachedInt32Memory0 === null || cachedInt32Memory0.byteLength === 0) {
        cachedInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachedInt32Memory0;
}

function getArrayJsValueFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    const mem = getUint32Memory0();
    const slice = mem.subarray(ptr / 4, ptr / 4 + len);
    const result = [];
    for (let i = 0; i < slice.length; i++) {
        result.push(takeObject(slice[i]));
    }
    return result;
}

function handleError(f, args) {
    try {
        return f.apply(this, args);
    } catch (e) {
        wasm.__wbindgen_exn_store(addHeapObject(e));
    }
}
/**
*/
export const OpState = Object.freeze({ Acked:0,"0":"Acked",Pending:1,"1":"Pending",Unsent:2,"2":"Unsent", });
/**
* Represents the data type of the [`ClientResponse`], in response to a
* server message
*/
export const ClientResponseType = Object.freeze({
/**
* A server message was an ack of a client update,
* causes the client to broadcast a new update.
*/
BroadcastLocalDocUpdate:0,"0":"BroadcastLocalDocUpdate",
/**
* A server message was a new document update from another client,
* triggers a local client document and cursor map update.
* A remote snapshot is also mapped to this type, since a snapshot is
* a special case of an entire document update ("insertion"),
*/
RemoteDocUpdate:1,"1":"RemoteDocUpdate",
/**
* A server message sends a new snapshot of the UserList, causes
* the client to update its local user list.
*/
UserList:2,"2":"UserList", });

const ClientFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_client_free(ptr >>> 0));
/**
* Standalone client with core operations and state for client document management with operational transform.
*/
export class Client {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Client.prototype);
        obj.__wbg_ptr = ptr;
        ClientFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ClientFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_client_free(ptr);
    }
    /**
    * @param {bigint} user_id
    * @returns {Client}
    */
    static new(user_id) {
        const ret = wasm.client_new(user_id);
        return Client.__wrap(ret);
    }
    /**
    * Represents the resulting document and cursor position after a client shifts their cursor/highlight or
    * updates the text in a document with a transformation represented by [`TextUpdate`].
    *
    * To maintain the "maximal responsiveness" property, local updates are always allowed without latency.
    *
    * Returns a JSON string serialization of a [`BroadcastLocalDocUpdate`] which represents the text transformation turning
    * the current document into the new document with the new cursor state.
    *
    * Single outstanding update property: The client only has one un-acked update at a time.
    * This way, the server only needs to apply one iteration of OT per client operation.
    * @param {string} new_document
    * @param {number} prev_doc_len
    * @param {(TextUpdate)[]} text_updates
    * @param {CursorPos} new_cursor_pos
    * @returns {string}
    */
    update_document_wasm(new_document, prev_doc_len, text_updates, new_cursor_pos) {
        let deferred4_0;
        let deferred4_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(new_document, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            const ptr1 = passArrayJsValueToWasm0(text_updates, wasm.__wbindgen_malloc);
            const len1 = WASM_VECTOR_LEN;
            _assertClass(new_cursor_pos, CursorPos);
            wasm.client_update_document_wasm(retptr, this.__wbg_ptr, ptr0, len0, prev_doc_len, ptr1, len1, new_cursor_pos.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            var r3 = getInt32Memory0()[retptr / 4 + 3];
            var ptr3 = r0;
            var len3 = r1;
            if (r3) {
                ptr3 = 0; len3 = 0;
                throw takeObject(r2);
            }
            deferred4_0 = ptr3;
            deferred4_1 = len3;
            return getStringFromWasm0(ptr3, len3);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred4_0, deferred4_1, 1);
        }
    }
    /**
    * Returns a bool indicating if the client should send its local update to the server.
    * If the client has only one operation in the bridge, this is true, and the front operation is marked as [`OpState::Pending`].
    * Otherwise, the client should buffer updates so this returns false.
    * @returns {boolean}
    */
    prepare_send_local_update() {
        const ret = wasm.client_prepare_send_local_update(this.__wbg_ptr);
        return ret !== 0;
    }
    /**
    * Updates client state based on a string which is assumed to be a JSON serialization of a `ServerMessage`.
    * Returns a serialized doc update if there is a new local operation to send the server.
    * This only occurs if `server_msg` is an ack to an buffered client operation.
    * Otherwise returns `None`, indicating Client internal state is updated, but no operation should be sent across the WS.
    * Note: JS cannot directly pass a `ServerMessage` because it is an enum with data, which is currently not supported by `wasm_bindgen`.
    *
    * This method is only called via WASM via JS.
    * TODO: What to do about methods like this which are only called in JS but exposed publicly? `log` will not link against a proper JS function if called in Rust.
    * @param {string} server_msg
    * @returns {ClientResponse | undefined}
    */
    handle_server_message(server_msg) {
        const ptr0 = passStringToWasm0(server_msg, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.client_handle_server_message(this.__wbg_ptr, ptr0, len0);
        return ret === 0 ? undefined : ClientResponse.__wrap(ret);
    }
    /**
    * @returns {bigint}
    */
    last_server_state_id() {
        const ret = wasm.client_last_server_state_id(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * Number of updates that have not been acked by the server (all except the first are unsent).
    * @returns {number}
    */
    buffer_len() {
        const ret = wasm.client_buffer_len(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * Get current client document
    * @returns {string}
    */
    document() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.client_document(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * Get current client cursor position
    * @returns {CursorPos | undefined}
    */
    cursor_pos() {
        const ret = wasm.client_cursor_pos(this.__wbg_ptr);
        return ret === 0 ? undefined : CursorPos.__wrap(ret);
    }
    /**
    * Get all cursor positions in the cursor map except the current user.
    * This is used to update the UI with the cursor positions of other users.
    * Returns a structure iterable by JS.
    * @returns {(UserCursorPos)[]}
    */
    cursor_pos_vec() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.client_cursor_pos_vec(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * Get the user id of the client
    * @returns {bigint}
    */
    user_id() {
        const ret = wasm.client_user_id(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
}

const ClientOpJsFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_clientopjs_free(ptr >>> 0));
/**
* [`ClientOperation`] which can be sent over the JS WASM boundary. Uses `JsValue` in place of complex types.
*/
export class ClientOpJs {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ClientOpJsFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_clientopjs_free(ptr);
    }
}

const ClientResponseFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_clientresponse_free(ptr >>> 0));
/**
* Client updates triggered by [`ServerMessage`]. Simple types that are WASM compatible
* and can be used to update the client state and UI. Effectively recreates a tagged C enum with data.
*/
export class ClientResponse {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(ClientResponse.prototype);
        obj.__wbg_ptr = ptr;
        ClientResponseFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ClientResponseFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_clientresponse_free(ptr);
    }
    /**
    * @returns {ClientResponseType}
    */
    message_type() {
        const ret = wasm.clientresponse_message_type(this.__wbg_ptr);
        return ret;
    }
    /**
    * Deserializes data as `UserList` if the data type is [`ClientResponseType::UserList`].
    * Otherwise returns None.
    * @returns {UserList | undefined}
    */
    get_user_list() {
        const ret = wasm.clientresponse_get_user_list(this.__wbg_ptr);
        return ret === 0 ? undefined : UserList.__wrap(ret);
    }
    /**
    * Returns the `BroadcastLocalDocUpdate` data if the message type is [`ClientResponseType::BroadcastLocalDocUpdate`].
    * The data is `BroadcastLocalDocUpdate` JSON stringified. Otherwise returns None.
    * @returns {string | undefined}
    */
    get_broadcast_doc_update() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.clientresponse_get_broadcast_doc_update(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            let v1;
            if (r0 !== 0) {
                v1 = getStringFromWasm0(r0, r1).slice();
                wasm.__wbindgen_free(r0, r1 * 1, 1);
            }
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {RemoteDocUpdate | undefined}
    */
    get_remote_doc_update() {
        const ret = wasm.clientresponse_get_remote_doc_update(this.__wbg_ptr);
        return ret === 0 ? undefined : RemoteDocUpdate.__wrap(ret);
    }
}

const CursorPosFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_cursorpos_free(ptr >>> 0));
/**
* Cursor position indices are in characters and not bytes
*/
export class CursorPos {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(CursorPos.prototype);
        obj.__wbg_ptr = ptr;
        CursorPosFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        CursorPosFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_cursorpos_free(ptr);
    }
    /**
    * @param {number} from
    * @param {number} to
    * @param {number} anchor
    * @param {number} head
    * @returns {CursorPos}
    */
    static new(from, to, anchor, head) {
        const ret = wasm.cursorpos_new(from, to, anchor, head);
        return CursorPos.__wrap(ret);
    }
    /**
    * @returns {number}
    */
    from() {
        const ret = wasm.cursorpos_from(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * @returns {number}
    */
    to() {
        const ret = wasm.cursorpos_to(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * @returns {number}
    */
    anchor() {
        const ret = wasm.cursorpos_anchor(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * @returns {number}
    */
    head() {
        const ret = wasm.cursorpos_head(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * Used by javascript for printing
    * @returns {string}
    */
    to_string() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.cursorpos_to_string(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @param {CursorPos} other
    * @returns {boolean}
    */
    equals(other) {
        _assertClass(other, CursorPos);
        const ret = wasm.cursorpos_equals(this.__wbg_ptr, other.__wbg_ptr);
        return ret !== 0;
    }
    /**
    * @returns {number}
    */
    highlight_len() {
        const ret = wasm.cursorpos_highlight_len(this.__wbg_ptr);
        return ret >>> 0;
    }
}

const RemoteDocUpdateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_remotedocupdate_free(ptr >>> 0));
/**
* Represents a local document update triggered by a remote client's update, applied to client state
*/
export class RemoteDocUpdate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(RemoteDocUpdate.prototype);
        obj.__wbg_ptr = ptr;
        RemoteDocUpdateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RemoteDocUpdateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_remotedocupdate_free(ptr);
    }
    /**
    * @returns {(TextUpdate)[]}
    */
    text_updates() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.remotedocupdate_text_updates(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

const RemoteUpdateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_remoteupdate_free(ptr >>> 0));
/**
*/
export class RemoteUpdate {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RemoteUpdateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_remoteupdate_free(ptr);
    }
    /**
    * @returns {bigint}
    */
    get source() {
        const ret = wasm.__wbg_get_remoteupdate_source(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @param {bigint} arg0
    */
    set source(arg0) {
        wasm.__wbg_set_remoteupdate_source(this.__wbg_ptr, arg0);
    }
    /**
    * @returns {bigint}
    */
    get dest() {
        const ret = wasm.__wbg_get_remoteupdate_dest(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @param {bigint} arg0
    */
    set dest(arg0) {
        wasm.__wbg_set_remoteupdate_dest(this.__wbg_ptr, arg0);
    }
    /**
    * @returns {bigint}
    */
    get state_id() {
        const ret = wasm.__wbg_get_remoteupdate_state_id(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @param {bigint} arg0
    */
    set state_id(arg0) {
        wasm.__wbg_set_remoteupdate_state_id(this.__wbg_ptr, arg0);
    }
    /**
    * @returns {TextOperation}
    */
    get operation() {
        const ret = wasm.remoteupdate_operation(this.__wbg_ptr);
        return TextOperation.__wrap(ret);
    }
    /**
    * @param {TextOperation} operation
    */
    set operation(operation) {
        _assertClass(operation, TextOperation);
        var ptr0 = operation.__destroy_into_raw();
        wasm.remoteupdate_set_operation(this.__wbg_ptr, ptr0);
    }
}

const TextOperationFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_textoperation_free(ptr >>> 0));
/**
* The `TextOperation` struct represents a sequence of operations
*/
export class TextOperation {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(TextOperation.prototype);
        obj.__wbg_ptr = ptr;
        TextOperationFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        TextOperationFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_textoperation_free(ptr);
    }
    /**
    * @returns {string}
    */
    to_json_string() {
        let deferred2_0;
        let deferred2_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.textoperation_to_json_string(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            var r3 = getInt32Memory0()[retptr / 4 + 3];
            var ptr1 = r0;
            var len1 = r1;
            if (r3) {
                ptr1 = 0; len1 = 0;
                throw takeObject(r2);
            }
            deferred2_0 = ptr1;
            deferred2_1 = len1;
            return getStringFromWasm0(ptr1, len1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred2_0, deferred2_1, 1);
        }
    }
    /**
    * @returns {any}
    */
    serialize_text_operation_js() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.textoperation_serialize_text_operation_js(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            if (r2) {
                throw takeObject(r1);
            }
            return takeObject(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {string} s
    * @returns {TextOperation}
    */
    static from_json_string(s) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(s, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.textoperation_from_json_string(retptr, ptr0, len0);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            if (r2) {
                throw takeObject(r1);
            }
            return TextOperation.__wrap(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string}
    */
    id_str() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.textoperation_id_str(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const TextUpdateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_textupdate_free(ptr >>> 0));
/**
* Represents a single text update, typically an input from a text editor.
* Text range `prev` is replaced with `text` in range `next`.
* A series of `TextUpdate` operations can be converted into a `TextOperation` struct.
* Closely resembles the CodeMirror `ChangeSet` representation, but is generalizable.
*/
export class TextUpdate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(TextUpdate.prototype);
        obj.__wbg_ptr = ptr;
        TextUpdateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    static __unwrap(jsValue) {
        if (!(jsValue instanceof TextUpdate)) {
            return 0;
        }
        return jsValue.__destroy_into_raw();
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        TextUpdateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_textupdate_free(ptr);
    }
    /**
    * @param {TextUpdateRange} prev
    * @param {TextUpdateRange} next
    * @param {string} text
    * @returns {TextUpdate}
    */
    static new(prev, next, text) {
        _assertClass(prev, TextUpdateRange);
        var ptr0 = prev.__destroy_into_raw();
        _assertClass(next, TextUpdateRange);
        var ptr1 = next.__destroy_into_raw();
        const ptr2 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len2 = WASM_VECTOR_LEN;
        const ret = wasm.textupdate_new(ptr0, ptr1, ptr2, len2);
        return TextUpdate.__wrap(ret);
    }
    /**
    * @returns {TextUpdateRange}
    */
    prev() {
        const ret = wasm.textupdate_prev(this.__wbg_ptr);
        return TextUpdateRange.__wrap(ret);
    }
    /**
    * @returns {TextUpdateRange}
    */
    next() {
        const ret = wasm.textupdate_next(this.__wbg_ptr);
        return TextUpdateRange.__wrap(ret);
    }
    /**
    * @returns {string}
    */
    text() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.textupdate_text(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const TextUpdateRangeFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_textupdaterange_free(ptr >>> 0));
/**
* A text update range `[from, to]`. Indices are in characters, not bytes.
*/
export class TextUpdateRange {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(TextUpdateRange.prototype);
        obj.__wbg_ptr = ptr;
        TextUpdateRangeFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        TextUpdateRangeFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_textupdaterange_free(ptr);
    }
    /**
    * @param {number} from
    * @param {number} to
    * @returns {TextUpdateRange}
    */
    static new(from, to) {
        const ret = wasm.textupdaterange_new(from, to);
        return TextUpdateRange.__wrap(ret);
    }
    /**
    * @returns {number}
    */
    from() {
        const ret = wasm.textupdaterange_from(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * @returns {number}
    */
    to() {
        const ret = wasm.textupdaterange_to(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * @returns {number}
    */
    len() {
        const ret = wasm.textupdaterange_len(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * @returns {boolean}
    */
    is_empty() {
        const ret = wasm.textupdaterange_is_empty(this.__wbg_ptr);
        return ret !== 0;
    }
}

const UserCursorPosFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_usercursorpos_free(ptr >>> 0));
/**
* A [`CursorPos`] with the corresponding User's user id.
*/
export class UserCursorPos {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(UserCursorPos.prototype);
        obj.__wbg_ptr = ptr;
        UserCursorPosFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        UserCursorPosFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_usercursorpos_free(ptr);
    }
    /**
    * @returns {bigint}
    */
    user_id() {
        const ret = wasm.usercursorpos_user_id(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @returns {CursorPos}
    */
    cursor_pos() {
        const ret = wasm.usercursorpos_cursor_pos(this.__wbg_ptr);
        return CursorPos.__wrap(ret);
    }
}

const UserInnerFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_userinner_free(ptr >>> 0));
/**
*/
export class UserInner {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(UserInner.prototype);
        obj.__wbg_ptr = ptr;
        UserInnerFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        UserInnerFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_userinner_free(ptr);
    }
    /**
    * @returns {bigint}
    */
    user_id() {
        const ret = wasm.userinner_user_id(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
    * @returns {string}
    */
    username() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.userinner_username(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
    * @returns {string}
    */
    color() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.userinner_color(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const UserListFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_userlist_free(ptr >>> 0));
/**
* Immutable snapshot of the users in the server at a point in time.
*/
export class UserList {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(UserList.prototype);
        obj.__wbg_ptr = ptr;
        UserListFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        UserListFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_userlist_free(ptr);
    }
    /**
    * @returns {(UserInner)[]}
    */
    users() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.userlist_users(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {string}
    */
    to_string() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.userlist_to_string(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

export function __wbg_userinner_new(arg0) {
    const ret = UserInner.__wrap(arg0);
    return addHeapObject(ret);
};

export function __wbg_usercursorpos_new(arg0) {
    const ret = UserCursorPos.__wrap(arg0);
    return addHeapObject(ret);
};

export function __wbindgen_object_drop_ref(arg0) {
    takeObject(arg0);
};

export function __wbindgen_is_string(arg0) {
    const ret = typeof(getObject(arg0)) === 'string';
    return ret;
};

export function __wbindgen_is_object(arg0) {
    const val = getObject(arg0);
    const ret = typeof(val) === 'object' && val !== null;
    return ret;
};

export function __wbindgen_is_undefined(arg0) {
    const ret = getObject(arg0) === undefined;
    return ret;
};

export function __wbindgen_string_new(arg0, arg1) {
    const ret = getStringFromWasm0(arg0, arg1);
    return addHeapObject(ret);
};

export function __wbindgen_object_clone_ref(arg0) {
    const ret = getObject(arg0);
    return addHeapObject(ret);
};

export function __wbg_new_abda76e883ba8a5f() {
    const ret = new Error();
    return addHeapObject(ret);
};

export function __wbg_stack_658279fe44541cf6(arg0, arg1) {
    const ret = getObject(arg1).stack;
    const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len1 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len1;
    getInt32Memory0()[arg0 / 4 + 0] = ptr1;
};

export function __wbg_error_f851667af71bcfc6(arg0, arg1) {
    let deferred0_0;
    let deferred0_1;
    try {
        deferred0_0 = arg0;
        deferred0_1 = arg1;
        console.error(getStringFromWasm0(arg0, arg1));
    } finally {
        wasm.__wbindgen_free(deferred0_0, deferred0_1, 1);
    }
};

export function __wbg_error_2fce0d3377409e9a(arg0, arg1) {
    console.error(getStringFromWasm0(arg0, arg1));
};

export function __wbg_textupdate_unwrap(arg0) {
    const ret = TextUpdate.__unwrap(takeObject(arg0));
    return ret;
};

export function __wbg_textupdate_new(arg0) {
    const ret = TextUpdate.__wrap(arg0);
    return addHeapObject(ret);
};

export function __wbg_crypto_d05b68a3572bb8ca(arg0) {
    const ret = getObject(arg0).crypto;
    return addHeapObject(ret);
};

export function __wbg_process_b02b3570280d0366(arg0) {
    const ret = getObject(arg0).process;
    return addHeapObject(ret);
};

export function __wbg_versions_c1cb42213cedf0f5(arg0) {
    const ret = getObject(arg0).versions;
    return addHeapObject(ret);
};

export function __wbg_node_43b1089f407e4ec2(arg0) {
    const ret = getObject(arg0).node;
    return addHeapObject(ret);
};

export function __wbg_require_9a7e0f667ead4995() { return handleError(function () {
    const ret = module.require;
    return addHeapObject(ret);
}, arguments) };

export function __wbindgen_is_function(arg0) {
    const ret = typeof(getObject(arg0)) === 'function';
    return ret;
};

export function __wbg_msCrypto_10fc94afee92bd76(arg0) {
    const ret = getObject(arg0).msCrypto;
    return addHeapObject(ret);
};

export function __wbg_randomFillSync_b70ccbdf4926a99d() { return handleError(function (arg0, arg1) {
    getObject(arg0).randomFillSync(takeObject(arg1));
}, arguments) };

export function __wbg_getRandomValues_7e42b4fb8779dc6d() { return handleError(function (arg0, arg1) {
    getObject(arg0).getRandomValues(getObject(arg1));
}, arguments) };

export function __wbg_newnoargs_e258087cd0daa0ea(arg0, arg1) {
    const ret = new Function(getStringFromWasm0(arg0, arg1));
    return addHeapObject(ret);
};

export function __wbg_call_27c0f87801dedf93() { return handleError(function (arg0, arg1) {
    const ret = getObject(arg0).call(getObject(arg1));
    return addHeapObject(ret);
}, arguments) };

export function __wbg_self_ce0dbfc45cf2f5be() { return handleError(function () {
    const ret = self.self;
    return addHeapObject(ret);
}, arguments) };

export function __wbg_window_c6fb939a7f436783() { return handleError(function () {
    const ret = window.window;
    return addHeapObject(ret);
}, arguments) };

export function __wbg_globalThis_d1e6af4856ba331b() { return handleError(function () {
    const ret = globalThis.globalThis;
    return addHeapObject(ret);
}, arguments) };

export function __wbg_global_207b558942527489() { return handleError(function () {
    const ret = global.global;
    return addHeapObject(ret);
}, arguments) };

export function __wbg_call_b3ca7c6051f9bec1() { return handleError(function (arg0, arg1, arg2) {
    const ret = getObject(arg0).call(getObject(arg1), getObject(arg2));
    return addHeapObject(ret);
}, arguments) };

export function __wbg_buffer_12d079cc21e14bdb(arg0) {
    const ret = getObject(arg0).buffer;
    return addHeapObject(ret);
};

export function __wbg_newwithbyteoffsetandlength_aa4a17c33a06e5cb(arg0, arg1, arg2) {
    const ret = new Uint8Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
    return addHeapObject(ret);
};

export function __wbg_new_63b92bc8671ed464(arg0) {
    const ret = new Uint8Array(getObject(arg0));
    return addHeapObject(ret);
};

export function __wbg_set_a47bac70306a19a7(arg0, arg1, arg2) {
    getObject(arg0).set(getObject(arg1), arg2 >>> 0);
};

export function __wbg_newwithlength_e9b4878cebadb3d3(arg0) {
    const ret = new Uint8Array(arg0 >>> 0);
    return addHeapObject(ret);
};

export function __wbg_subarray_a1f73cd4b5b42fe1(arg0, arg1, arg2) {
    const ret = getObject(arg0).subarray(arg1 >>> 0, arg2 >>> 0);
    return addHeapObject(ret);
};

export function __wbindgen_throw(arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
};

export function __wbindgen_memory() {
    const ret = wasm.memory;
    return addHeapObject(ret);
};

